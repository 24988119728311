import React from 'react';
import { useState, useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget'
import { navigate } from 'gatsby';


const useNetlifyIdentity = () => {

    let queryParams = {};

    let refreshInterval;


    if (typeof window !== "undefined") {
        const searchParams = new URLSearchParams(window.location.search);

        for (const [key, value] of searchParams) {
            queryParams[key] = value;
        }

    }

    const toQueryString = (params) => {
        return Object.entries(params)
            .filter(([key, _]) => key !== "redirected")
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    };



    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)
    const [JWT, setJWT] = useState(null)
    const [authReady, setAuthReady] = useState(false)
    const [loginFailed, setLoginFailed] = useState(false)

    function refreshJWT() {

        netlifyIdentity.refresh().then((jwt) => {
            console.log('JWT refreshed');
            setJWT(jwt);
        })
            .catch((error) => {
                console.error('Error refreshing token:', error);
            });
    }

    useEffect(() => {

        const handleLogin = (user) => {
            // console.log('login event')

            setUser(user)

            const tokenExpiry = user.token.expires_at / 1000; // moment of expiry in seconds
            const now = Date.now() / 1000; // current moment in seconds


            // Refresh jwt token 5 minutes before expiration
            if (tokenExpiry - now < 300) {
                refreshJWT()
            }


            netlifyIdentity.close()

            //case cookie
            if (user && user.app_metadata && user.app_metadata.roles && user.app_metadata.roles[0] === 'authenticatedUser') {
                const currentPath = window.location.pathname

                if (currentPath === '/') {

                    if (queryParams.redirected) {

                        navigate('/main' + '?' + toQueryString(queryParams))
                    }

                    else {
                        navigate('/main')
                    }
                }

                return





            }


            const urlString = '/.netlify/functions/check-access'

            const token = user && user.token.access_token

            fetch(urlString, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(res => {
                    if (!res.ok) {
                        throw Error('Log in to see content')
                    }

                    return res.json()
                })

                .then(data => {

                    if (data.granted) {

                        const currentPath = window.location.pathname

                        if (currentPath === '/' && data.authenticatedUser) {
                            if (queryParams.redirected) {

                                navigate('/main' + '?' + toQueryString(queryParams))
                            }

                            else {
                                navigate('/main')
                            }
                        }

                    }

                    else {
                        netlifyIdentity.logout()
                        netlifyIdentity.close()

                        setLoginFailed(true)

                        netlifyIdentity.init()




                    }
                })

                .catch((err) => {
                    setError(err.message)
                })



        }

        const handleLogout = () => {

            setUser(null)
            navigate('/')
            netlifyIdentity.init()
        }


        const handleInit = (user) => {

            setAuthReady(true);

            if (user) {
                setUser(user);


            }
        };

        //below allows us to style the netlify identity window
        netlifyIdentity.on('open', () => {
            // Fetch all iframes with the id 'netlify-identity-widget'
            const iframes = document.querySelectorAll('#netlify-identity-widget');

            iframes.forEach((iframe) => {
                const iframeDocument = iframe?.contentDocument || iframe?.contentWindow.document;

                // Now you can target elements within the iframe
                const callOutElements = iframeDocument?.getElementsByClassName('callOut');

                if (callOutElements && callOutElements.length > 0) {
                    Array.from(callOutElements).forEach((element) => {
                        element.style.display = 'none';
                    });
                }
            });

            // Inject custom font
            // const style = document.createElement('style');
            // style.textContent = `
            // @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
            // .modalContent {
            //     font-family: 'Inter', sans-serif !important;
            // }`;
            // iframeDocument.head.appendChild(style);


            // const modalContent = iframeDocument?.getElementsByClassName('modalContent')

            // if (modalContent && modalContent.length > 0) {
            //     // modalContent[0].style.fontFamily = 'Inter, sans-serif';
            // }
        })

        netlifyIdentity.on('login', handleLogin)

        netlifyIdentity.on('logout', handleLogout)

        netlifyIdentity.on('init', handleInit)


        netlifyIdentity.init()

        // return () => {
        //     netlifyIdentity.off('login', handleLogin)
        //     netlifyIdentity.off('logout', handleLogout)
        //     netlifyIdentity.off('init', handleInit)
        // }


    }, [])

    useEffect(() => {

        // Set up the interval to check token expiration

        if (user && user.token && !refreshInterval) {
            refreshInterval = setInterval(() => {

                const tokenExpiry = user.token.expires_at / 1000; // moment of expiry in seconds
                const now = Date.now() / 1000; // current moment in seconds

                // Refresh the token 5 minutes before expiration
                if (tokenExpiry - now < 300) {
                    refreshJWT()
                }
            }, 60000); // Check every minute
        }

        return () => {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        };

    }, [user]);

    const login = () => {
        netlifyIdentity.open()
    }


    const logout = () => {
        netlifyIdentity.logout()

    }

    const [cloudRunToken, setCloudrunToken] = useState(null)

    function generateCloudRunToken() {

        netlifyIdentity.refresh().then(res => {

            const urlString = '/.netlify/functions/get-token'

            // calling the function with said url
            fetch(urlString,
                {
                    headers: {
                        'Authorization': `Bearer ${res}`
                    }
                }
            )
                .then(res => {

                    //throwing error if no result could be generated
                    if (!res.ok) {
                        throw Error('Log in to see content')
                    }

                    //otherwise return result and json'ify it
                    return res.json()
                })
                .then(data => {

                    setCloudrunToken(data.token)
                })



        })



    }

    useEffect(() => {
        if (user) {
            generateCloudRunToken()
        }
        
    }, [user]);





    return { authReady, user, error, login, logout, loginFailed, cloudRunToken, generateCloudRunToken, JWT }
}

export default useNetlifyIdentity;